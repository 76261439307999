import { axiosClient } from "../axios";

export function insertSingleWorkExperience(data) {
  return axiosClient.post("work-experiences/insert", data);
}
export function updateSingleWorkExperience(data) {
  return axiosClient.post(`work-experiences/${data.id}/update`, data);
}

export function remove(workExpId) {
  return axiosClient.delete(`work-experiences/${workExpId}/delete`);
}
