import * as user from "./modules/user";
import * as jobPosition from "./modules/job-position";
import * as quiz from "./modules/quiz";
import * as country from "./modules/country";
import * as language from "./modules/language";
import * as work from "./modules/work";
import * as search from "./modules/search";
import * as itSkill from "./modules/it-skill";
import * as interview from "./modules/interview";

const enumMapping = {
  country: country.countryEnum,
  userStatus: user.statusEnum,
  howKnowIason: user.howKnowIasonEnum,
  competenceCenter: user.competenceCenterEnum,
  qualification: user.qualificationLevelEnum,
  withdrawMotivation: user.withdrawMotivationEnum,
  incomingResumeStatus: user.incomingResumeStatusEnum,
  itSkill: itSkill.skillEnum,
  itLevel: itSkill.levelEnum,
  interviewMark: interview.markEnum,
  langSkill: language.skillEnum,
  langLevel: language.levelEnum,
  engCertificate: language.englishCertificateEnum,
  workRole: work.roleEnum,
  workSeniority: work.seniorityEnum,
  workArea: work.areaEnum,
  workContract: work.contractTypeEnum,
  workRemote: work.remoteTypeEnum,
  jobLocation: jobPosition.locationEnum,
  jobPriority: jobPosition.priorityEnum,
  jobCloseReason: jobPosition.closeReasonEnum,
  quizTopic: quiz.quizTopicEnum,
  searchOperator: search.searchOperatorEnum,
};

export function getEnumByProperty(property) {
  return enumMapping[property];
}

export function getLabel(property, code, altLabel = false) {
  if (getEnumByProperty(property)) {
    const currentEnum = getEnumByProperty(property);
    return altLabel
      ? currentEnum.getAltLabel(parseInt(code))
      : currentEnum.getLabel(parseInt(code));
  }
  return "";
}
export function getKey(property, code) {
  if (getEnumByProperty(property)) {
    return getEnumByProperty(property).getKey(parseInt(code));
  }
  return "";
}

export function getCodeByKey(property, key) {
  if (getEnumByProperty(property)) {
    return getEnumByProperty(property).getCode(key);
  }
  return "";
}

export function getCodeLabelSelectItems(
  property,
  options = { altLabels: false, sortByLabel: false, excludeNone: false }
) {
  const currentEnum = getEnumByProperty(property);
  if (!currentEnum) return [];

  const items = currentEnum.getCodesList().map((code) => {
    const text = options.altLabels
      ? currentEnum.getAltLabel(code)
      : currentEnum.getLabel(code);

    return { value: code, text: text ?? currentEnum.getLabel(code) };
  });

  if (options.sortByLabel) {
    items.sort((a, b) => a.text.localeCompare(b.text));
  }

  if (options.excludeNone) {
    return items.filter((item) => item.text.toLowerCase() !== "none");
  }

  return items;
}

export function getListBasedOnProperty(property) {
  if (getEnumByProperty(property)) {
    return getEnumByProperty(property).getLabelsList();
  }
  return [];
}
