import Axios from "axios";
import Store from "@/store";
import Router from "@/router";
import { snackBarMapError } from "@/utils/snackbar";

const axiosConfig = {
  baseURL: "/v1/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 30000,
};

const axiosClient = Axios.create(axiosConfig);

axiosClient.interceptors.request.use((config) => {
  const token = Store.state.user.token;
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorPayload = snackBarMapError(error);
    if (errorPayload.code === 401) {
      Store.dispatch("user/setDefaultState");
      Store.dispatch("test/setDefaultState");
      const fromPath = Router.currentRoute.fullPath;
      const currentRouteName = Router.currentRoute.name;
      if (currentRouteName !== "login") {
        let queryObj = { from: fromPath };
        Router.push({ name: "login", query: queryObj });
      }
    }
    if (errorPayload.code === 403) {
      Router.push({ name: "dashboard" });
    }
    Store.dispatch("setSnackBar", errorPayload);
    return Promise.reject(error);
  }
);

export { axiosClient };
